@font-face {
    font-family: 'Neue Haas Unica';
    src: local('Neue Haas Unica Light'), local('Neue-Haas-Unica-Light'),
    url('NeueHaasUnica-Light.woff2') format('woff2'),
    url('NeueHaasUnica-Light.woff') format('woff'),
    url('NeueHaasUnica-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Neue Haas Unica';
    src: local('Neue Haas Unica Regular'), local('Neue-Haas-Unica-Regular'),
    url('NeueHaasUnica-Regular.woff2') format('woff2'),
    url('NeueHaasUnica-Regular.woff') format('woff'),
    url('NeueHaasUnica-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: local('Neue Haas Unica Medium'), local('Neue-Haas-Unica-Medium'),
    url('NeueHaasUnica-Medium.woff2') format('woff2'),
    url('NeueHaasUnica-Medium.woff') format('woff'),
    url('NeueHaasUnica-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: local('Neue Haas Unica Bold'), local('Neue-Haas-Unica-Bold'),
    url('NeueHaasUnica-Bold.woff2') format('woff2'),
    url('NeueHaasUnica-Bold.woff') format('woff'),
    url('NeueHaasUnica-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
